import api from '../../Api';
import { getToken } from '../../shared/utils';

export const DELIVERY_TYPE = "DELIVERY";
export const CS_TYPE = "COPY_SHOP";

/**
 * Printing service to launch API calls to our servers.
 */
class PrintingService {

  /**
   * Launch the order process to print all the selected documents.
   * @param {string} type Type of order (#CS_TYPE or #DELIVERY_TYPE)
   * @param {array} selectedDocuments Array with selected documents identifiers
   * @param {boolean} useFreePages Identify if the user received free pages on the UX.
   * @param {number} freePages Amount of free pages
   * @param {number} addressId Selected address identifier. Only filled for delivery type.
   * @param {number} copyShopId Selected copy shop identifier. Only filled for copy shop type.
   * @param {number} discount Discount identifier. Only filled when discount was applied.
   */
  static async requestOrder(type, selectedDocuments, useFreePages, freePages, addressId, copyShopId, discountId) {

    const request = {
      "type": type,
      "documents": selectedDocuments,
      "useFreePages": useFreePages,
      "freePages": freePages,
      "addressId": addressId,
      "copyShopId": copyShopId,
      "discountId": discountId
    }

    return api.post("/v2/print", request, {headers: {'X-AUTH-TOKEN': getToken()}})
  }

  static async cancelOrder(externalId) {
    return api.put(`/v2/print/${externalId}/cancel`, {}, {headers: {'X-AUTH-TOKEN': getToken()}});
  }

  static async get(externalId) {
    return api.get(`/v2/print/${externalId}`, {headers: {'X-AUTH-TOKEN': getToken()}});
  }
}

export default PrintingService;
import React, { Component,  } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { IntlProvider } from 'react-intl';
import { Switch, Route, Redirect } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { PAGE_VIEW } from './shared/EventsNames';
import ReactGA from 'react-ga';

import {
  AccountPage,
  ActivatePage,
  AdBlockPage,
  CreateAccountPage,
  DeliveryPage,
  DocumentPage,
  ForgotPasswordPage,
  LoginPage,
  PaymentResultPage,
  RefPage,
  ResetPasswordPage,
  SharedDocumentsPage,
  SwitchToUserPage,
} from './Containers';

import './fonts/fonts.css';
import './colors.css';
import './animations.css';
import './App.css';
import { redesignFreshdeskWidgetLauncher } from './shared/Freshdesk';
import Error404 from './Containers/Error404';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { AdBlockContext } from './contexts/AdBlock';
import { marketingLoader } from './MarketingLoader';
import { marketing_landing_pages } from './marketing_config';
import CompetitionPage from './Competition/Containers/CompetitionPage';
import CopyshopPage from './Components/Locations/Containers/CopyshopPage';
import { logger } from './shared/Logger';
import { experimentDebugger } from '@marvelapp/react-ab-test';
import { TestProvider } from './contexts/TestContext'
import mixpanel from 'mixpanel-browser';
import AiTutorLandingPage from './AiTutor/Containers/AiTutorLadingPage';
import { MuiTheme } from './MaterialUi/MuiTheme';
import { ThemeProvider } from '@material-ui/core/styles';
import TempNotification from './TempNotifications/TempNotification';
import PrintingPage from './Printing/Containers/PrintingPage';
import CancelPrintingPage from './Printing/Containers/CancelPrintingPage';
import SuccessPrintingPage from './Printing/Containers/SuccessPrintingPage';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
       adBlockDetected: false
    }

    this.detectAdBlocker = this.detectAdBlocker.bind(this);
  }

  componentDidMount() {


  experimentDebugger.enable();

    this.detectAdBlocker();

    // Configure Facebook Pixel
    const ReactPixel = require('react-facebook-pixel').default;
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
    
    // Configure Google Analytics
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }

    // Listen for route changes here
    if (this.props.history) {

      this.props.history.listen((location, action) => {
        
        if (this.props.loggedIn) {
          this.context.mixpanel.track(PAGE_VIEW);
          logger.info("Move to new page. Location: " + location.pathname)
          ReactPixel.pageView();
        }

        if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
          ReactGA.set({ page: location.pathname }); // Update the user's current page
          ReactGA.pageview(location.pathname); // Record a pageview for the given page
        }
        
      });
    }

    // Track current view
    this.context.mixpanel.track(PAGE_VIEW);
    ReactPixel.pageView();
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.set({ page: window.location.pathname }); // Update the user's current page
      ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
    }
  }


  detectAdBlocker() {

    const head = document.getElementsByTagName('head')[0];

    const noAdBlockDetected = () => {
        this.setState({
            adBlockDetected: false
        });
    }
    const adBlockDetected = () => {
        this.setState({
            adBlockDetected: true
        });
    }
    // clean up stale bait
    const oldScript = document.getElementById('adblock-detection');
    if (oldScript) {
        head.removeChild(oldScript);
    }
    // we will dynamically generate some 'bait'.
    const script = document.createElement('script');
    script.id = 'adblock-detection';
    script.type = 'text/javascript';
    script.src = '/advertisement.js';
    script.onload = noAdBlockDetected;
    script.onerror = adBlockDetected;
    head.appendChild(script);
  }

  render() {

    if (window.FreshworksWidget) {
      if (this.props.loggedIn === true) {
        window.FreshworksWidget('show', 'launcher');
        redesignFreshdeskWidgetLauncher();
      } else {
        window.FreshworksWidget('hide', 'launcher');
      }
    }

    return (
      <GoogleOAuthProvider clientId="594235193214-1fmrpdljk2l1a16b01t3mk4ctjrqec5r.apps.googleusercontent.com">
        <ThemeProvider theme={MuiTheme}>
          <IntlProvider locale={this.props.locale} messages={this.props.messages}>
            <AdBlockContext.Provider value={{adBlockDetected: this.state.adBlockDetected}}>
              <TestProvider mixpanel={mixpanel}>
                <React.Fragment>

                  { this.props.loggedIn === true && <TempNotification /> }

                  <Helmet>
                    <meta property="og:url"           content={this.props.location.pathname} />
                    <meta property="og:type"          content="website" />
                    <meta property="og:title"         content="Print your courses for free!" />
                    <meta property="og:description"   content="Save your money by printing your courses for free with Zerocopy" />
                    <meta property="og:image"         content="preview-card.png" />
                    <meta property="fb:app_id" content={process.env.REACT_APP_FACEBOOK_ID} />
                  </Helmet>

                  <Switch>
                    <Route path="/" exact component={marketingLoader(CreateAccountPage, marketing_landing_pages)} />
                    <Route path="/login" exact component={LoginPage} />
                    <Route path="/create-account" exact component={marketingLoader(CreateAccountPage, marketing_landing_pages)} />
                    <Route path="/forgot-password" exact component={ForgotPasswordPage} />
                    <Route path="/activate/:activationKey" component={ActivatePage} />
                    <Route path="/resetpassword/:resetPasswordKey" component={ResetPasswordPage} />
                    <Route path="/ref/:ref_id" component={RefPage} />

                    <Route path="/adblock" exact component={AdBlockPage} />
                    <Route path="/documents" exact component={DocumentPage} />
                    <Route path="/user/account" exact component={AccountPage} />
                    <Route path="/user/deliveries" exact component={DeliveryPage} />
                    <Route path="/shared-documents" exact component={SharedDocumentsPage} />

                    <Route path="/payment/success" exact component={PaymentResultPage} />
                    <Route path="/payment/canceled" exact component={PaymentResultPage} />

                    <Route path="/switch/:user" component={SwitchToUserPage} />
                    <Route path="/locations" exact render={ props => <Redirect to="/#our_locations" /> } />

                    <Route path="/competition" exact component={CompetitionPage} />

                    <Route path="/copyshop" exact component={CopyshopPage} />
                    <Route exact path="/printing/:id/success">
                      <SuccessPrintingPage />
                    </Route>
                    <Route exact path="/printing/:id/cancel">
                      <CancelPrintingPage />
                    </Route>
                    <Route exact path="/printing">
                      <PrintingPage />
                    </Route>

              <Route component={Error404} />
            </Switch>
          </React.Fragment>
          </TestProvider >
          </AdBlockContext.Provider>
        </IntlProvider>
        </ThemeProvider>
      </GoogleOAuthProvider>
    );
  }
}

App.propTypes = {
  location: PropTypes.object.isRequired
}

App.contextTypes = {
    mixpanel: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    locale: state.locale.lang,
    messages: state.locale.messages,
    loggedIn: state.security.loggedIn
  };
}

const mapDispatchToProps = { }

export default connect(mapStateToProps, mapDispatchToProps)(App);

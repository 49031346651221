import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import PrintingService from "../Services/PrintingService";
import CustomSpinner from "../../Components/Commons/Spinner";
import { FormattedMessage, useIntl } from "react-intl";
import { PrivateTemplate } from "../../Templates";
import { Helmet } from "react-helmet";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { fetchDocuments } from "../../store/actions";
import { logger } from "../../shared/Logger";
import { WarningIcon } from "../../Components/Icons";

const CancelPrintingPage = (props) => {

    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const intl = useIntl();
    const dispatcher = useDispatch();
    const [error, setError] = useState(false);
    const [messageKey, setMessageKey] = useState();
    const [messageParameters, setMessageParameters] = useState({});

    useEffect(() => {
        if (id) {

            PrintingService.cancelOrder(id).then(response => {
                dispatcher(fetchDocuments());
                setError(false);
            }).catch(error => {
                logger.error("Error while try to cancel printing order", JSON.stringify(error));
                
                setError(true);
                if (error.response.status === 404) {
                    setMessageKey("printing.msg.notFound");
                } else {
                    setMessageKey("errors.unexpected");
                }

            }).finally(() => setLoading(false))

        }
    }, [id]);

    return (
        <PrivateTemplate>
            <Helmet>
                <title>Zerocopy - {intl.formatMessage({id: "xxx", defaultMessage: "Cancel printing order"})}</title>
            </Helmet>
            <Container fluid className="text-center">
                <Row className="mb-3">
                    <Col className="pl-0">
                        {!loading && !error && <CancelMessage /> }
                        {!loading && error && <ErrorMessage messageKey={messageKey} messageParameters={messageParameters} /> }
                        {loading && <CustomSpinner messageKey="printing.cancel.loading" /> }
                    </Col>
                </Row>
                {!loading && <Row>
                    <Col className="pl-0">
                        <Link to="/" className="btn btn-outline-secondary text-decoration-none mt-2">
                            <FormattedMessage id="printing.btnBack" />
                        </Link>
                    </Col>
                </Row>}
            </Container>
        </PrivateTemplate>
    );

}

export default CancelPrintingPage;

const CancelMessage = () => {
    return (
        <Card>
            <CardBody>
                <FormattedMessage id="printing.cancel" />
            </CardBody>
        </Card>
    );
}

const ErrorMessage = ({messageKey, messageParameters}) => {

    return (
        <Card>
            <CardBody>
                <WarningIcon />
                <div className="mt-3 text-danger">
                    <FormattedMessage id={messageKey} values={messageParameters} />
                </div>
            </CardBody>
        </Card>
    );
}
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DOC_VALID_STATES_FOR_SELECTION } from '../../shared/utils';
import { toggleDocument } from '../../store/actions';
import PrintingTypeSelector from '../../Printing/Components/PrintingTypeSelector';
import { Button } from 'reactstrap';
import { useMixpanel } from '../../contexts/Mixpanel';
import { PRINT_BUTTON_CLICK } from '../../shared/EventsNames';

const DocumentListButtons = (props) => {

    const docs = useSelector(state => state.documents.list);
    const selected = useSelector(state => state.documents.selected);
    const [validDocs, setValidDocs] = useState([]);
    const [open, setOpen] = useState(false);
    const dispatcher = useDispatch();
    const mixpanel = useMixpanel();

    useEffect(() => {

      if (docs) {
        setValidDocs(
          docs.filter(doc => DOC_VALID_STATES_FOR_SELECTION.includes(doc.status))
        );
      }

    }, [docs]);

    const toggle = () => {
      setOpen(previousState => {

        if (!previousState) {
          mixpanel.track(PRINT_BUTTON_CLICK);
        }

        return !previousState
      });
    }

    const handlePrintingClick = (e) => {

      // If we don't have any selected documents we must select all of the valid ones
      if (!selected || selected?.documents?.length === 0) {

        if (validDocs.length !== 0) {
          validDocs.forEach(d => {
              dispatcher(toggleDocument(d.id, d.numberOfPages));
          });

          toggle();

        }

      } else {
        toggle();
      }

    }

    return (
      <div>
        <div className="d-flex justify-content-md-end align-items-sm-strech justify-content-lg-start flex-column flex-md-row mb-3">
          <Button disabled={validDocs?.length === 0} color='primary' data-test="btn-delivery-request" className="mr-2" onClick={handlePrintingClick} style={{minWidth: "120px"}}>
            <FormattedMessage id="documents.btn.order" />
          </Button>
        </div>
        {open && <PrintingTypeSelector toggle={toggle}/> }
      </div>
    );
}

export default DocumentListButtons;

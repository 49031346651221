import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, ButtonGroup, Card, CardBody, CardHeader, Col, Container, Input, Row, Spinner } from "reactstrap";
import { PrivateTemplate } from "../../Templates";
import { logger } from "../../shared/Logger";
import ConfigurationService, { CS_TRANSACTION_FEE, DAILY_FREE_PAGES, DELIVERY_ENABLED, PARTNER_EMAIL_ENABLED, PRICE_PER_PRINTED_PAGE, PRINT_SMALL_ORDER_WARNING } from "../../Services/ConfigurationService";
import { AddressService, DeliveryService, LocationService, UserService } from "../../Services";
import GlobalLimitService from "../../Services/GlobalLimitService";
import { globalErrorHandling, isNotNull, isOutOfViewport, MAX_PROFILE_LEVEL } from "../../shared/utils";
import { ToggleIcon, TrashIcon } from "../../Components/Icons";
import { toggleDocument } from "../../store/actions";
import { Link, useLocation } from "react-router-dom";
import AddressModalForm from "../../Components/Address/AddressModalForm";
import Select from "react-select";
import DeliveryPaymentMethods from "../../Components/Deliveries/DeliveryPaymentMethods";
import PrintingService, { CS_TYPE, DELIVERY_TYPE } from "../Services/PrintingService";
import { useForm } from "react-hook-form";
import { success as successNotification, error as errorNotification } from 'react-notification-system-redux';
import { useIsVisible } from "../../hoc/VisibilityObserver";
import { useMixpanel } from "../../contexts/Mixpanel";
import { PRINT_CHECKOUT_CLICK, PRINT_PAYMENT_CREATED, PRINT_SELECT_LOCATION, PRINT_SELECT_TYPE } from "../../shared/EventsNames";

// FIXME This is temporary, we won't launch this in the first release.
const hidePromoCode = true;

const PrintingPage = () => {

  const intl = useIntl();
  const dispatcher = useDispatch();
  const location = useLocation();
  const mixpanel = useMixpanel();

  const [pricingConfig, setPricingConfig] = useState(undefined);
  const [smallOrderWarningLimit, setSmallOrderWarningLimit] = useState(0);
  const [pricePerPage, setPricePerPage] = useState(0);
  const [defaultFreePages, setDefaultFreePages] = useState(0);
  const [availableFreePages, setAvailableFreePages] = useState(0);
  const [defaultTransactionFee, setDefaultTransactionFee] = useState(0);
  const [addresses, setAddresses] = useState([]);
  const [locations, setLocations] = useState([]);
  const [disabled, setDisable] = useState(false);
  const [disabledDeliveryMaxPages, setDisableDeliveryMaxPages] = useState(false);
  const [disabledDelivery, setDisableDelivery] = useState(false);
  const [transactionFee, setTransactionFee] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [freePages, setFreePages] = useState(false);
  const [profileFilled, setProfileFilled] = useState(true);
  const [useFreePages, setUseFreePages] = useState(false);
  const [pagesToPay, setPagesToPay] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPagesPlusAds, setTotalPagesPlusAds] = useState(0);
  const [printType, setPrintType] = useState();
  const [openAddressForm, setOpenAddressForm] = useState(false);
  const [address, setAddress] = useState();
  const [addressError, setAddressError] = useState(false);
  const [copyShop, setCopyShop] = useState();
  const [copyShopError, setCopyShopError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [discount, setDiscount] = useState();
  const [maxPages, setMaxPages] = useState(0);
  const [partnerEmailsFeatureEnabled, setPartnerEmailsFeatureEnabled] = useState(true);
  const [freePrintedPages, setFreePrintedPages] = useState(0);
  const [unlockHighlight, setUnlockHighlight] = useState(false);
  
  const user = useSelector(state => state.user.user);
  const selected = useSelector((state) => state.documents.selected);
  const documents = useSelector((state) => state.documents.list.filter(doc => state.documents.selected.documents.includes(doc.id)));
  const isPartnerEmailsEnabled = useSelector(state => state.consent.partnerEmails);

  const unlockRef = useRef(null);
  const unlockVisible = useIsVisible(unlockRef);

  useEffect(() => {
    
    if (location) {
      const type = new URLSearchParams(location.search).get("type");
      if (type) {
        setPrintType(type);
      } else {
        setPrintType(DELIVERY_TYPE);
      }
    }

  }, [location]);

  useEffect(() => {
    if (selected && selected.documents?.length > 0) {
      
      DeliveryService.getPricing(selected.documents).then(response => {
        setPricingConfig(response.data);
      }).catch(error => {
        setPricingConfig(null);
        setDisableDeliveryMaxPages(true);
      });

      ConfigurationService.getConfigurations([DAILY_FREE_PAGES, PRICE_PER_PRINTED_PAGE, CS_TRANSACTION_FEE, DELIVERY_ENABLED, PARTNER_EMAIL_ENABLED, PRINT_SMALL_ORDER_WARNING])
      .then(response => {
        
        setPricePerPage(parseInt(response.data.find(config => config.key === PRICE_PER_PRINTED_PAGE).value) / 100); // Defined in cents
        setDefaultFreePages(parseInt(response.data.find(config => config.key === DAILY_FREE_PAGES).value));
        setDefaultTransactionFee(parseInt(response.data.find(config => config.key === CS_TRANSACTION_FEE).value) / 100); // Defined in cents
        setDisableDelivery(response.data.find(config => config.key === DELIVERY_ENABLED).value !== "true");
        setPartnerEmailsFeatureEnabled(response.data.find(config => config.key === PARTNER_EMAIL_ENABLED).value === "true");
        setSmallOrderWarningLimit(parseInt(response.data.find(config => config.key == PRINT_SMALL_ORDER_WARNING).value));

      }).catch(error => {
        logger.error("Error while try to obtain configuration values", error);
        setDisable(true);
      })

      AddressService.fetchAll()
      .then(response => setAddresses(response.data))
      .catch(error => logger.error("Error loading addresses for print chart", JSON.stringify(error)))

      LocationService.fetchLocations().then(response => {
        setLocations(response.data);
      }).catch(error => {
        logger.error("Error loading locations for print chart", JSON.stringify(error));
      });

      UserService.getConfiguration().then(response => {
        setMaxPages(response.data.maxPagesPerOrder);
        setFreePrintedPages(response.data.freePrintedPages);
      }).catch(error => {
        logger.error("Error fetching user configuration", error)
        setMaxPages(0);
        setFreePrintedPages(0);
      });

    }

  }, [selected]);

  useEffect(() => {

    if (totalPagesPlusAds <= 0 || maxPages <= 0) {
      return;
    }

    if (totalPagesPlusAds < maxPages) {

      GlobalLimitService.getCounterForCurrentMonth().then(response => {
        setFreePages(response.data.currentDailyValue < response.data.dailyLimit);
      }).catch(error => {
        setFreePages(false);
        logger.error("Error fetching global limit", error);
        setDisable(true);
      });

      setDisableDeliveryMaxPages(false);
    } else {
      
      setDisableDeliveryMaxPages(true);
    }

  }, [totalPagesPlusAds, maxPages]);

  useEffect(() => {

    if (disabledDeliveryMaxPages || disabledDelivery) {
      setPrintType(CS_TYPE);
    }

  }, [disabledDeliveryMaxPages, disabledDelivery]);

  useEffect(() => {
    setProfileFilled(user?.profileLevel === MAX_PROFILE_LEVEL);
  }, [user]);

  useEffect(() => {

    if (documents?.length > 0) {
      setTotalPages(documents.reduce((acc, currentValue) => acc + currentValue.numberOfPages, 0));
      setTotalPagesPlusAds(documents.reduce((acc, currentValue) => acc + currentValue.numberOfPages + currentValue.numberOfFullPageAds, 0));
    }

  }, [documents]);

  useEffect(() => {

    // To have access to free pages user must have the profile filled and partner email must be enabled
    if (!freePages || !profileFilled || (partnerEmailsFeatureEnabled && !isPartnerEmailsEnabled) || (freePrintedPages >= defaultFreePages)) {
      setUseFreePages(false);
    } else {
      setUseFreePages(true);
    }

  }, [freePages, profileFilled, isPartnerEmailsEnabled, partnerEmailsFeatureEnabled, freePrintedPages, defaultFreePages]);

  useEffect(() => {

    setAvailableFreePages(Math.max(0, defaultFreePages - freePrintedPages));

  }, [freePrintedPages, defaultFreePages]);

  useEffect(() => {

    if (printType === DELIVERY_TYPE && pricingConfig) {
      setDeliveryFee(pricingConfig.price);
      setTransactionFee(0);
    } else {
      setDeliveryFee(0);
      setTransactionFee(defaultTransactionFee);
    }

  }, [printType, pricingConfig, defaultTransactionFee]);

  useEffect(() => {

    let newPagesToPay = 0;
    
    if (totalPages !== 0) {

      if (!useFreePages) {
        newPagesToPay = totalPages;
      } else if (totalPages > availableFreePages) {
        newPagesToPay = totalPages - availableFreePages;
      }
    }
    
    setPagesToPay(newPagesToPay);

  }, [totalPages, useFreePages, availableFreePages]);

  useEffect(() => {

    if (isNotNull(address)) {
      return;
    }

    let mainAddress = undefined;

    if (addresses.length === 1) {
      mainAddress = addresses[0]
    } else {
      const mainAddresses = addresses.filter((a) => a.main === true);
      if (mainAddresses.length !== 0) {
        mainAddress = mainAddresses[0];
      }
    }

    setAddress(mainAddress);
    
  }, [address, addresses]);

  const lockClick = () => {
    setUnlockHighlight(true);
    setTimeout(() => setUnlockHighlight(false), 2000);

    if (!unlockVisible) {
      unlockRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
    }
  }

  const handleBack = (e) => {
    if (isSubmitting) {
      e?.preventDefault();
    }
  }

  const handleOnDelete = (doc) => {
    if (!disabled && !isSubmitting) {
      dispatcher(toggleDocument(doc.id, doc.numberOfPages, doc.numberOfFullPageAds));
    }
  };

  const handleOnCopyShopSelect = (selectedCopyShop) => {
    mixpanel.track(PRINT_SELECT_LOCATION, {type_of_printing: printType, location: selectedCopyShop.name});
    setCopyShop(selectedCopyShop);
    setAddressError(false);
    setCopyShopError(false);
  }

  const handleOnAddressSelect = (selectedAddress) => {
    mixpanel.track(PRINT_SELECT_LOCATION, {type_of_printing: printType});
    setAddress(selectedAddress);
    setAddressError(false);
    setCopyShopError(false);
  }

  const handleOnNewAddress = () => {
    setOpenAddressForm(true);
  }

  const handleAddressToggle = () => {
    setOpenAddressForm(prev => !prev);
  }

  const handleSaveNewAddress = (addressData) => {
    AddressService.save(undefined, addressData).then(response => {
      
      setOpenAddressForm(false);
      AddressService.fetchAll()
      .then(response => {
        setAddresses(response.data);
        setAddress(null);
      })
      .catch(error => logger.error("Error loading addresses for print chart", JSON.stringify(error)));

    }).catch(error => {
      logger.error("Error while save the address from print chart", JSON.stringify(error));
    });
  }

  const handleOrderClick = () => {

    if (printType === DELIVERY_TYPE && !address) {
      setAddressError(true)
    } else if (printType === CS_TYPE && !copyShop) {
      setCopyShopError(true)
    } else {

      setIsSubmitting(true);
      
      mixpanel.track(PRINT_CHECKOUT_CLICK, {type_of_printing: printType});

      PrintingService.requestOrder(printType, selected.documents, useFreePages, Math.min(availableFreePages, totalPages), address?.id, copyShop?.id, discount)
        .then(response => {

          if (response.data.stripeResult) {

              mixpanel.track(PRINT_PAYMENT_CREATED, {type_of_printing: printType});

              window.stripeApi.redirectToCheckout({
                sessionId: response.data.stripeResult
              }).then((result) => {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
                console.log(result)
              });
      
          } else {
            // FIXME Free request, some result message should be present.
          }

        }).catch(error => {

          const errorObj = globalErrorHandling(error);

          // 243 = Amount of free pages requested is different than allowed
          if (errorObj?.errorCode === 243) {

            UserService.getConfiguration().then(response => {
              setMaxPages(response.data.maxPagesPerOrder);
              setFreePrintedPages(response.data.freePrintedPages);
            }).catch(error => {
              logger.error("Error fetching user configuration", error)
              setMaxPages(0);
              setFreePrintedPages(0);
            });

          }
          
          let msg = intl.formatMessage({"id": errorObj.errorKey}, errorObj.errorParams);
          
          dispatcher(errorNotification({
            message: msg,
            position: 'tc',
            autoDismiss: 0,
          }));

        }).finally(() => {
          setIsSubmitting(false);
        });

    }

  }

  if (!selected || !selected.documents || selected.documents.length === 0) {
    return <EmptyCart />;
  }

  let totalPrice = (pagesToPay * pricePerPage) + deliveryFee + transactionFee;

  totalPrice = Math.max(totalPrice, 0);

  return (
    <PrivateTemplate>
      <Helmet>
          <title>Zerocopy - {intl.formatMessage({id:"printing.title"})}</title>
      </Helmet>
      <Container fluid>
        <Row className="mb-3">
          <Col className="pl-0">
            <h2><FormattedMessage id="printing.title"/></h2>
          </Col>
        </Row>

        { disabled && <DisabledMessage /> }
        { !disabled && (!isPartnerEmailsEnabled || !profileFilled) && <UnlockFreePages ref={unlockRef} partnerEmailsEnabled={isPartnerEmailsEnabled} profileFilled={profileFilled} highlight={unlockHighlight} /> }
        { !disabled && printType === CS_TYPE && smallOrderWarningLimit !== 0 && smallOrderWarningLimit > totalPages && <SmallOrderWarningMessage /> }

        <Row>
          <Col className="pl-0" xs={12} md={6} lg={8}>
            <Card>
              <CardHeader className="font-weight-bold">
                <FormattedMessage id="printing.documents.title" />
              </CardHeader>
              <CardBody className="py-2">
                <DocumentList documents={documents} feePerPage={pricePerPage} onDelete={handleOnDelete}/>
              </CardBody>
            </Card>

            <Card className="mt-3">
              <CardHeader className="font-weight-bold">
                <FormattedMessage id={printType === DELIVERY_TYPE ? "printing.addressSelection.title" : "printing.copyShopSelection.title"} />
              </CardHeader>
              <CardBody className="py-2 px-1">
                {printType === DELIVERY_TYPE && <AddressSelector disabled={isSubmitting || disabled} addresses={addresses} onSelect={handleOnAddressSelect} selected={address} onNewAddress={handleOnNewAddress} /> }
                {printType === DELIVERY_TYPE && addressError && <span className="text-danger small mt-2"><FormattedMessage id="printing.addressSelection.required" /></span> }
                {printType === CS_TYPE && <LocationSelector disabled={isSubmitting || disabled} locations={locations} selected={copyShop} onSelect={handleOnCopyShopSelect} /> }
                {printType === CS_TYPE && copyShopError && <span className="text-danger small mt-2"><FormattedMessage id="printing.copyShopSelection.required" /></span> }
              </CardBody>
            </Card>
          </Col>
          
          <Col xs={12} md={6} lg={4} className="mt-3 mt-md-0 pl-0">
            <Card>
              <CardHeader className="font-weight-bold">
                <FormattedMessage id="printing.overview.title" />
              </CardHeader>
              <CardBody className="py-2">
                <Row>
                  <Col className="px-0">
                    <FormattedMessage id="printing.overview.printing" /> (<FormattedMessage id="global.words.pages" values={{pages: totalPages}} />)
                  </Col>
                  <Col className="px-0 text-right" xs={3} lg={5} xl={4}>
                    <FormattedNumber value={totalPages * pricePerPage} minimumFractionDigits={2} maximumFractionDigits={2} />&nbsp;€
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col className="px-0">
                    <FormattedMessage id="printing.overview.type" />
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col xs={9} sm={7} lg={8} className="px-0">
                    <SwitchType type={printType} onChange={setPrintType} deliveryDisabled={disabledDelivery || disabledDeliveryMaxPages || isSubmitting} copyShopDisabled={isSubmitting} />
                  </Col>
                  <Col className="px-0 text-right" xs={3} sm={5} lg={4}>
                    <FormattedNumber value={printType === CS_TYPE ? transactionFee : deliveryFee} minimumFractionDigits={2} maximumFractionDigits={2} />&nbsp;€
                  </Col>
                </Row>
                <Row className={`border-top my-2 pt-2`}>
                  <Col className="px-0">
                    <FormattedMessage id="printing.overview.freePages" values={{pages: Math.min(availableFreePages, totalPages)}}/>
                  </Col>
                  <Col className="px-0 text-right" xs={3} lg={5} xl={4}>
                    {useFreePages && <span>-&nbsp;<FormattedNumber value={Math.min(availableFreePages, totalPages) * pricePerPage} minimumFractionDigits={2} maximumFractionDigits={2} />&nbsp;€ </span>}
                    {!useFreePages && <div onClick={lockClick} className="c-pointer d-flex align-items-center justify-content-end"><span className="fs-6 material-symbols-outlined">lock</span><span className="text-hover-underline text-hover-bold"><FormattedMessage id="printing.overview.freePages.locked" /></span></div>}
                  </Col>
                </Row>
                { !hidePromoCode &&
                <Row className={`${useFreePages ? "" : "border-top my-2 pt-2"}`}>
                  <Col className="px-0">
                    {!discount && <DiscountCodeField onChange={(data) => setDiscount(data)} disabled={disabled || isSubmitting}/>}
                    {discount && <div className="d-flex align-items-center">
                        <FormattedMessage id="xxx" defaultMessage="{code} applied" values={{code: discount}} />
                        <span className="material-symbols-outlined c-pointer ml-2 bg-secondary text-white rounded" onClick={() => setDiscount(null)}>close_small</span>
                      </div>}
                  </Col>
                  {discount && <Col className="px-0 text-right" xs={3} lg={5} xl={4}>
                  </Col> }
                </Row> }
                <Row className="border-top pt-2">
                  <Col className="px-0">
                    <FormattedMessage id="printing.overview.balance" />
                  </Col>
                  <Col className="px-0 text-right" xs={3} lg={5} xl={4}>
                    <FormattedNumber value={totalPrice} minimumFractionDigits={2} maximumFractionDigits={2} />&nbsp;€
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

        </Row>
        <Row>
          <Col className="pl-0">
            <div className="text-center">
              <Button color="primary" className="mt-3" onClick={handleOrderClick} disabled={isSubmitting || disabled || (printType === DELIVERY_TYPE && (disabledDelivery || disabledDeliveryMaxPages))}>
                <FormattedMessage id="printing.btnCheckout" />
                {isSubmitting && <Spinner size="sm" className="ml-2" />}
              </Button>
              <Link to="/" className="btn btn-outline-secondary text-decoration-none mt-3 ml-2" onClick={handleBack}>
                <FormattedMessage id="printing.btnBack" />
              </Link>
            </div>
            <div className="mt-3 py-2 px-3 border small">
              <FormattedMessage id="deliveries.request.paymentMethods" />
              <DeliveryPaymentMethods />
            </div>
          </Col>
        </Row>
      </Container>


      <AddressModalForm
          isOpen={openAddressForm}
          toggle={handleAddressToggle}
          onSubmit={handleSaveNewAddress}
          isNew={true}
          />

    </PrivateTemplate>
  );
}

export default PrintingPage;

const EmptyCart = () => {

  const intl = useIntl();

  return (
    <PrivateTemplate>
      <Helmet>
        <title>Zerocopy - {intl.formatMessage({id:"printing.emptyCart"})}</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col className="text-center">
            <Card>
              <CardBody>
                  <span className="material-symbols-outlined" style={{fontSize: "4rem"}}>shopping_cart</span>
                  <div className="mt-3">
                    <FormattedMessage id="printing.emptyCart" />
                  </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="text-center mt-3">
          <Col>
            <Link to="/" className="btn btn-outline-secondary text-decoration-none">
              <FormattedMessage id="printing.btnBack" />
            </Link>
          </Col>
        </Row>
      </Container>
    </PrivateTemplate>
  );
}

const DocumentList = ({documents, onDelete, feePerPage}) => {

  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (documents && documents.length > 0) {
      setTotalPages(documents.reduce((acc, currentValue) => acc + currentValue.numberOfPages, 0));
    }
  }, [documents]);

  const handleClick = (e, doc) => {
    e.preventDefault();

    onDelete(doc)
  }

  const content = documents.map((doc, index) => {

    return (
      <Row key={index} className={index === 0 ? null : "mt-1"}>
        <Col className="pl-0 text-break">
          {doc.name}
        </Col>
        <Col className="px-0 text-right" xs={4} sm={3} md={4} lg={3} xl={2}>
          <span className="mr-2"><FormattedMessage id="global.words.pages" values={{pages: doc.numberOfPages}} /></span>
          <TrashIcon className="c-pointer" onClick={(e) => handleClick(e, doc)} />
        </Col>
        <Col className="px-0 text-right" xs={3} md={2} xl={1}>
          <FormattedNumber value={doc.numberOfPages * feePerPage} minimumFractionDigits={2} maximumFractionDigits={2} />&nbsp;€
        </Col>
      </Row>
    );
  });

  return (
    <div>
      {content}
      <Row className="border-top pt-2 mt-2">
        <Col className="pl-0">
          <FormattedMessage id="xxx" defaultMessage="Total" />
        </Col>
        <Col className="px-0 text-right" xs={4} sm={3} md={4} lg={3} xl={2}>
          <span className="mr-2"><FormattedMessage id="global.words.pages" values={{pages: totalPages}} /></span>
          <TrashIcon className="invisible" />
        </Col>
        <Col className="px-0 text-right" xs={3} md={2} xl={1}>
          <FormattedNumber value={totalPages * feePerPage} minimumFractionDigits={2} maximumFractionDigits={2} />&nbsp;€
        </Col>
      </Row>
    </div>
  );
}

const SwitchType = ({type, onChange, deliveryDisabled = false, copyShopDisabled = false}) => {

  const mixpanel = useMixpanel();
  
  const handleOnDelivery = (e) => {
    e?.currentTarget?.blur();
    if (type !== DELIVERY_TYPE) {
      mixpanel.track(PRINT_SELECT_TYPE, {type_of_printing: DELIVERY_TYPE});
      onChange(DELIVERY_TYPE);
    }
  }

  const handleOnCopyShop = (e) => {
    e?.currentTarget?.blur();
    if (type !== CS_TYPE) {
      mixpanel.track(PRINT_SELECT_TYPE, {type_of_printing: CS_TYPE});
      onChange(CS_TYPE);
    }
  }

  return (
    <ButtonGroup>
      <Button size="xs" color="secondary-light" outline active={type === DELIVERY_TYPE} onClick={handleOnDelivery} disabled={deliveryDisabled}>
        <FormattedMessage id="printing.overview.type.delivery" />
      </Button>
      <Button size="xs" color="secondary-light" outline active={type === CS_TYPE} onClick={handleOnCopyShop} disabled={copyShopDisabled}>
        <FormattedMessage id="printing.overview.type.copyShop" />
      </Button>
    </ButtonGroup>
  );

}

const PrintingPageGlobalMessage = ({color = "danger", children}) => {
  return (
    <Row>
      <Col className="pl-0">
        <Alert color={color}>
          {children}
        </Alert>
      </Col>
    </Row>
  );
}

const UnlockFreePages = forwardRef(({partnerEmailsEnabled, profileFilled, highlight}, ref) => {

  const [percetange, setPercentage] = useState(0);

  useEffect(() => {

    let localPercentage = 0;

    if (partnerEmailsEnabled || profileFilled) {
      localPercentage = 50;
    }

    setPercentage(localPercentage);

  }, [partnerEmailsEnabled, profileFilled]);

  return (
    <div ref={ref}>
      <Row className="mb-2">
        <Col className="pl-0">
          <Card className="border-primary-light">
            <CardBody className={`${highlight ? "highlight":"bg-primary-light"}`}>
              <div className="d-flex align-items-center" style={{gap: ".5rem"}}>
                <span className="material-symbols-outlined">lock</span>
                <span className="font-weight-bold"><FormattedMessage id="printing.unlock.title" /></span>
                <span className="">{percetange}%</span>
              </div>
              <UnlockFreePagesCondition enabled={profileFilled} textKey="printing.unlock.profile" defaultMessage="Complete your profile" />
              <UnlockFreePagesCondition enabled={partnerEmailsEnabled} textKey="printing.unlock.partnerEmails" defaultMessage="Enable partner emails" />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
});

const UnlockFreePagesCondition = ({enabled, textKey, defaultMessage}) => {
  return (
    <div className="d-flex align-items-center" style={{gap: ".5rem", marginLeft: "2rem"}}>
      <div className={`rounded ${enabled ? "bg-primary" : "bg-text-color"}`} style={{width: "8px", height: "8px"}}></div>
      <div className={`${enabled ? "text-strikethrough" : ""}`}>
        <FormattedMessage id={textKey} defaultMessage={defaultMessage} />
      </div>
    </div>
  );
}

const SmallOrderWarningMessage = () => {
  return (
    <PrintingPageGlobalMessage color="warning">
      <FormattedMessage id="printing.copyShops.smallAmountOfPages" />
    </PrintingPageGlobalMessage>
  );
}

const DisabledMessage = () => {
  return (
    <PrintingPageGlobalMessage>
      <FormattedMessage id="printing.disabled" />
    </PrintingPageGlobalMessage>
  );
}

const DisabledDeliveryMessage = ({maxPages}) => {
  return (
    <PrintingPageGlobalMessage>
      <FormattedMessage id="deliveries.request.message.error.maxPages" values={{max: maxPages}} />
    </PrintingPageGlobalMessage>
  );
}

const DisabledDelivery= () => {
  return (
    <PrintingPageGlobalMessage>
      <FormattedMessage id="deliveries.request.message.disabled" />
    </PrintingPageGlobalMessage>
  );
}

const LocationSelector = ({locations, selected, onSelect, disabled}) => {

  const intl = useIntl();
  const [open, setOpen] = useState(false);

  const toggle = (e) => {
    e?.preventDefault();

    setOpen(!open);
  }

  const handleOnSelect = (location) => {
    toggle();
    onSelect(location);
  }

  const generateOptionLabel = (location) => {

    let label = location.name + " (" + location.address;
    let cityLabel = ""
    
    if (location.postalCode) {
      cityLabel += location.postalCode
    }
    
    if (location.cityName) {
      cityLabel += " " + location.cityName;
    }

    if (cityLabel !== "") {
      label += " - " + cityLabel.trim(); 
    }

    label += ")";

    return label;
  }

  return (
    <Select isDisabled={disabled} options={locations} value={selected} onChange={handleOnSelect}
      placeholder={intl.formatMessage({id:"printing.copyShopSelection.placeholder"})}
      getOptionValue={(option) => option}
      getOptionLabel={generateOptionLabel}
      styles={{
            menu: provided => ({ ...provided, zIndex: 9999 }),
            singleValue: provided => ({...provided, color: "var(--darkblue-color)"}) 
          }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: 'var(--primary-light-color)',
          primary: 'var(--primary-color)',
        },
      })} />
  );
}

const AddressSelector = ({addresses, selected, onSelect, onNewAddress, disabled}) => {

  const intl = useIntl();
  const [open, setOpen] = useState(false);

  const toggle = (e) => {
    e?.preventDefault();
    setOpen(!open);
  }

  const handleOnSelect = (selectedOption) => {
    toggle();
    onSelect(selectedOption);
  }

  const generateOptionLabel = (option) => {
    return option.street + " " + option.streetNumber +  " - " + option.postalCode + " " + option.city + " (" + option.name + " " + option.surname + ")"
  };

  return (
    <div>
      <Select isDisabled={disabled} options={addresses} value={selected} onChange={handleOnSelect}
        placeholder={intl.formatMessage({id:"printing.addressSelection.placeholder"})}
        getOptionValue={(option) => option}
        getOptionLabel={generateOptionLabel}
        styles={{
              menu: provided => ({ ...provided, zIndex: 9999 }),
              singleValue: provided => ({...provided, color: "var(--darkblue-color)"}) 
            }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'var(--primary-light-color)',
            primary: 'var(--primary-color)',
          },
        })} />
        <div className="mt-2">
          <Button size="sm" color="link" className="p-0" onClick={onNewAddress} disabled={disabled}>
            <FormattedMessage id="address.choose.btn.add" />
          </Button>
        </div>
      </div>
  );
}

const DiscountCodeField = ({onChange, disabled}) => {

  const intl = useIntl();
  const { register, handleSubmit, setError, formState: { errors } } = useForm({
    mode: "onSubmit"
  });
  const { ref: codeRef, ...registerCode } = register("code", { required: true });

  const submit = (data) => {

    // FIXME Validate promo code here
    onChange(data.code);
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          <Input id="printingCode" data-test="printing-request-promo-code" type="text" name="code"
            placeholder={intl.formatMessage({id: "xxx", defaultMessage: "Reedem promo code"})}
            className="form-control-xs"
            invalid={errors?.code ? true : false}
            readOnly={disabled}
            {...registerCode}
            innerRef={codeRef}/>
        </div>
        <div className="ml-2">
          <Button type="submit" size="sm" data-test='delivery-promocode-button' block disabled={disabled}>
            <FormattedMessage id="xxx" defaultMessage="Apply" />
          </Button>
        </div>
      </div>
      {errors?.code?.type === "required" && <p className="text-danger small mb-0"><FormattedMessage id="validations.field.required" /></p>}
      {errors?.code?.type === "manual" && <p className="text-danger small mb-0">{errors.code.message}</p>}
    </form>
  );

}